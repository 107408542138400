import { ReactNode } from "react";
import { concat, filter, fromPromise, map, merge, pipe } from "wonka";
import { Override } from "../types/index";
import { dateToStr, strToDate } from "../utils/dates";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek, Weekdays } from "./Calendars";
import {
  AssistType as AssistTypeDto,
  DailyHabit as HabitDto,
  DayOfWeek as DayOfWeekDto,
  DefenseAggression as DefenseAggressionDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentType,
  SubscriptionType,
  ThinPerson as ThinPersonDto,
} from "./client";
import { Category, EventColor, EventSubType, PrimaryCategory } from "./EventMetaTypes";
import { Recurrence } from "./OneOnOnes";
import { isHabit as isHabitPlanner, TaskOrHabitIdentifyingFields } from "./Planner";
import { Smurf } from "./Projects";
import { NotificationKeyStatus, nullable, TransformDomain } from "./types";
import { User } from "./Users";

export const isHabit = (item: unknown): item is Habit => isHabitPlanner(item as TaskOrHabitIdentifyingFields);

// WARNING BEFORE EDITING: These values are copied from the server, this is what is set by the server when an undefined value is set
// Be careful changing these to make sure they are in sync with the server in /src/main/java/ai/reclaim/server/assist/TaskOrHabit.java
// TODO: Find a way to add this to OpenAPI (ma)
export const DefaultAutoDeclineText =
  "Hi! This is Reclaim, {name}'s virtual assistant. I'm sorry, but {name} has a commitment at this time, and it's one of the last open slots available to get it done. Can you find another time to meet?";
export const DefaultDefendedDescription =
  "Reclaim has blocked this time off for {name} to work on an important commitment. Reclaim defended this time because it's one of the last available in {name}'s schedule. Please find another time to meet with {name}.";

export enum DefenseAggression {
  None = "NONE",
  Low = "LOW",
  Default = "DEFAULT",
  High = "HIGH",
  Max = "MAX",
}

export const HABIT_DEFENSIVENESS_COPY: Record<DefenseAggression, { label: ReactNode; description: ReactNode }> = {
  NONE: { label: "Always free", description: "Habits will always show as free and available time on your calendar." },
  LOW: {
    label: "Least defensive",
    description:
      "Marks the Habit as busy when one slot remains for its minimum duration, or 30m before the Habit is scheduled to begin.",
  },
  DEFAULT: {
    label: "More defensive",
    description:
      "Marks the Habit as busy when two slots remain for its minimum duration, or 60m before the Habit is scheduled to begin.",
  },
  HIGH: {
    label: "Most defensive",
    description:
      "Marks the Habit as busy when one slot remains for its maximum duration, or 24h before the Habit is scheduled to begin.",
  },
  MAX: { label: "Always busy", description: "Habits will always show as busy and unavailable time on your calendar." },
};

export const HABIT_DEFENSIVENESS_ORDER: DefenseAggression[] = [
  DefenseAggression.None,
  DefenseAggression.Low,
  DefenseAggression.Default,
  DefenseAggression.High,
  DefenseAggression.Max,
];

export type HabitType = Override<
  HabitDto,
  {
    readonly effectivePriority?: Smurf;

    readonly created: Date;
    readonly updated?: Date;
    readonly deleted?: boolean;

    index: number;
    enabled: boolean;
    title: string;
    eventCategory: PrimaryCategory;
    eventColor?: EventColor;
    daysActive?: DayOfWeek[];
    idealDay?: DayOfWeek | null;
    recurrence: Recurrence | null;
    priority?: Smurf;
    snoozeUntil?: Date | null;
    defenseAggression: DefenseAggression;
    eventSubType: EventSubType;
    timesPerPeriod?: number;
  }
>;

export class Habit implements HabitType {
  readonly id: number;
  readonly effectivePriority?: Smurf;

  readonly created: Date;
  readonly updated?: Date;
  readonly deleted?: boolean;

  title: string;
  index: number;
  enabled: boolean;
  eventCategory: PrimaryCategory;

  campaign?: string;
  defendedDescription: string;
  additionalDescription: string;
  windowStart: string;
  windowEnd: string;
  idealTime: string;
  idealDay: DayOfWeek | null;
  durationMin: number;
  durationMax: number;
  timesPerPeriod?: number;
  daysActive?: DayOfWeek[];
  recurrence: Recurrence | null;
  invitees: ThinPersonDto[];
  alwaysPrivate: boolean;
  defenseAggression: DefenseAggression;
  elevated: boolean;
  eventColor?: EventColor;
  adjusted: boolean;
  notification: boolean;
  eventSubType: EventSubType;
  reservedWords: string[];
  autoDecline: boolean;
  autoDeclineText: string;

  snoozeUntil?: Date | null;

  type: AssistTypeDto;

  recurringAssignmentType: RecurringAssignmentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: string | null | undefined;
  eventFilter?: object | undefined;

  // TODO (IW): Find a better place for this (and Task.getColor, Calendar.getColor)
  static getColor(user: User, habit: Habit): EventColor | undefined {
    return !!habit.eventColor && habit.eventColor?.key !== EventColor.Auto.key
      ? habit.eventColor
      : EventColor.getColor(user, habit.eventCategory);
  }
}

export function dtoToHabit(dto: HabitDto): Habit {
  return {
    ...dto,
    id: !!dto.id ? (dto.id as number) : undefined, // strip 0 ids (long id == 0)
    eventCategory: !!dto.eventCategory ? Category.get(dto.eventCategory as unknown as string) : undefined,
    eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
    recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
    daysActive: dto.daysActive as unknown as DayOfWeek[],
    idealDay: dto.idealDay as unknown as DayOfWeek,
    snoozeUntil: nullable(dto.snoozeUntil, strToDate),
    timesPerPeriod: dto.timesPerPeriod,
    defenseAggression: dto.defenseAggression as unknown as DefenseAggression,
    created: strToDate(dto.created),
    updated: strToDate(dto.updated),
    eventSubType: EventSubType.get(dto.eventSubType),
  } as Habit; // TODO (IW) Ditch casting once swagger required/optional fields are configured properly
}

export function habitToDto(habit: Partial<Habit>): Partial<HabitDto> {
  const data: Partial<HabitDto> = {
    ...habit,
    eventCategory: habit.eventCategory?.toJSON() as unknown as HabitDto["eventCategory"],
    eventColor: (EventColor.Auto === habit.eventColor ? null : habit.eventColor?.toJSON()) as HabitDto["eventColor"],
    // IMPORTANT: null means "clear it out", undefined means "don't change it" (see RAI-3230)
    recurrence: (habit.recurrence?.key as RecurrenceDto) || undefined,
    daysActive: habit.daysActive as unknown as DayOfWeekDto[],
    idealDay: habit.idealDay as unknown as DayOfWeekDto,
    // TODO ask Patrick why? we really need to stick to a format (ma)
    snoozeUntil: nullable(habit.snoozeUntil, dateToStr),
    timesPerPeriod: habit.timesPerPeriod,
    defenseAggression: habit.defenseAggression as unknown as DefenseAggressionDto,
    created: dateToStr(habit.created),
    updated: dateToStr(habit.updated),
    eventSubType: habit.eventSubType?.key,
  };

  return data;
}

const DailyHabitSubscription = {
  subscriptionType: SubscriptionType.DailyHabit,
};

export class HabitsDomain extends TransformDomain<Habit, HabitDto> {
  resource = "Habit";
  cacheKey = "habits";
  pk = "id";

  public serialize = habitToDto;
  public deserialize = dtoToHabit;

  watchWs$ = pipe(
    this.ws.subscription$$(DailyHabitSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = () =>
    pipe(
      fromPromise(this.list()),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = () => {
    return pipe(
      concat<Habit[] | null>([this.list$$(), this.watchAll$]),
      upsert((h) => this.getPk(h)),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number) => {
    return pipe(
      this.listAndWatch$$(),
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.deserializeResponse(this.api.assist.getDailyHabits);

  get = this.deserializeResponse(this.api.assist.getDailyHabit);

  create = this.deserializeResponse((habit: Habit) => {
    const notificationKey = this.generateUid("create");

    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

    return this.api.assist
      .create(this.serialize(habit) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  patch = this.deserializeResponse((habit: Partial<Habit>) => {
    // exclude additionalDescription when updating until that feature has a field in the UI
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, additionalDescription, ...rest } = habit;
    const notificationKey = this.generateUid("patch", habit);

    this.expectChange(notificationKey, id!, rest, true);

    return this.api.assist
      .patch(id!, this.serialize(rest) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  // Note, this reIndex method exists as a one off for lack of time to create a better genaric abstraction to patch that accounts for the expected server
  // result of non-directly interacted changes. Maybe this is OK if we dont need to do this much? W.E.T. principles applied here.
  reIndex = this.deserializeResponse(
    (vars: { habitId: number; habits: Array<Partial<Habit> | { id: number; index: number }> }) => {
      const habit = vars.habits.find((h) => h.id === vars.habitId);
      if (!habit) throw new Error("Cant find chengedHabit to reindex");
      const notificationKey = this.generateUid("patch", habit);

      // Here we need to wait for the knock on changes resulting from this index change
      vars.habits.forEach(({ id, ...rest }) => !!id && this.expectChange(notificationKey, id, rest));

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.assist
        .patch(vars.habitId!, this.serialize(habit) as HabitDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    }
  );

  delete = (id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.assist
      .delete1(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  createDefaults = (data: { lunch: boolean; catchup: boolean }) => {
    // TODO createDefaultHabits types are not cast on the server / OpenAPI (ma)
    return this.api.assist.createDefaultHabits(data);
  };
}

export const defaultHabit: Partial<Habit> = {
  title: "",
  daysActive: Weekdays,
  eventCategory: PrimaryCategory.Personal,
  eventColor: EventColor.Auto,
  enabled: true,
  defenseAggression: DefenseAggression.Default,
  durationMin: 15,
  durationMax: 120,
  windowStart: "08:00:00",
  windowEnd: "18:00:00",
  idealTime: "09:00:00",
  recurrence: Recurrence.Weekly,
  alwaysPrivate: false,
  invitees: [],
  index: 0,
};

export class PresetHabit {
  static WeeklyStatus = new PresetHabit({
    title: "✍️ Weekly Status",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "15:00:00",
    windowEnd: "18:00:00",
    idealTime: "16:00:00",
    durationMin: 30,
    durationMax: 60,
    timesPerPeriod: 1,
    idealDay: DayOfWeek.Friday,
  });
  static Meditation = new PresetHabit({
    title: "☯️ Meditation",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "08:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Exercise = new PresetHabit({
    title: "💪 Exercise",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "18:00:00",
    idealTime: "08:30:00",
    durationMin: 30,
    durationMax: 60,
  });
  static Coding = new PresetHabit({
    title: "💻 Coding",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "18:00:00",
    idealTime: "10:00:00",
    durationMin: 60,
    durationMax: 120,
  });
  static Networking = new PresetHabit({
    title: "🤝 Networking",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Saturday, DayOfWeek.Sunday],
    windowStart: "10:00:00",
    windowEnd: "17:00:00",
    idealTime: "11:00:00",
    durationMin: 30,
    durationMax: 60,
  });
  static Reading = new PresetHabit({
    title: "📖 Reading",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "18:00:00",
    windowEnd: "21:00:00",
    idealTime: "20:00:00",
    durationMin: 30,
    durationMax: 120,
  });
  static TakeWalk = new PresetHabit({
    title: "🌳 Take a Walk",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "12:00:00",
    windowEnd: "14:00:00",
    idealTime: "13:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Writing = new PresetHabit({
    title: "✏️ Writing",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "18:00:00",
    windowEnd: "21:00:00",
    idealTime: "19:00:00",
    durationMin: 30,
    durationMax: 120,
  });
  static CatchUp = new PresetHabit({
    title: "📨 Catch Up",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "09:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Lunch = new PresetHabit({
    title: "🍱 Lunch",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "11:30:00",
    windowEnd: "14:00:00",
    idealTime: "12:00:00",
    durationMin: 30,
    durationMax: 60,
    reservedWords: [
      "lunch",
      "almuerzo",
      "mittagessen",
      "déjeuner",
      "pranzo",
      "dinar",
      "午餐",
      "ランチ",
      "점심",
      "frokost",
      "obiad",
      "almoço",
    ],
  });
  static BizOps = new PresetHabit({
    title: "💸 BizOps Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Friday],
    windowStart: "15:00:00",
    windowEnd: "18:00:00",
    idealTime: "16:00:00",
    durationMin: 30,
    durationMax: 60,
  });
  static CustomerSupport = new PresetHabit({
    title: "💬 Customer Support",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "09:00:00",
    durationMin: 30,
    durationMax: 60,
  });

  static MetricsReview = new PresetHabit({
    title: "📈 Metrics Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 60,
    durationMax: 120,
    recurrence: Recurrence.Monthly,
  });

  static BudgetReview = new PresetHabit({
    title: "💸 Budget Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 180,
    durationMax: 240,
    recurrence: Recurrence.Quarterly,
  });

  static SprintPlanning = new PresetHabit({
    title: "🏃 Sprint Planning",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 60,
    durationMax: 120,
    recurrence: Recurrence.Biweekly,
  });

  static MonthlyReport = new PresetHabit({
    title: "✍️ Monthly Report",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 120,
    durationMax: 180,
    recurrence: Recurrence.Monthly,
  });

  static HouseCleaning = new PresetHabit({
    title: "🏡 Housecleaning",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Saturday, DayOfWeek.Sunday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "08:00:00",
    durationMin: 360,
    durationMax: 480,
    recurrence: Recurrence.Quarterly,
  });

  static FocusTime = new PresetHabit({
    title: "🧑‍💻 Focus Time",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "08:00:00",
    durationMin: 120,
    durationMax: 240,
    timesPerPeriod: 0,
    recurrence: null,
  });

  // **** social issue habits ****

  // **** end social issue habits ****

  static isPresetHabit(title: string): boolean {
    const titles = Object.keys(PresetHabit)
      .filter((k) => !!PresetHabit[k]["title"])
      .map((k) => PresetHabit[k]["title"]);
    return titles.includes(title.trim());
  }

  constructor(public readonly data: Partial<Habit>) {}

  toJSON() {
    return this.data;
  }
}

export function userDefaultHabit(user?: User | null, overrides: Partial<Habit> = {}): Partial<Habit> {
  // additionalDescription gets used in google cal, and that requires no line breaks or extra spaces, so ensure its clean here
  if (overrides.additionalDescription) {
    overrides.additionalDescription = overrides.additionalDescription
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/[\t ]+\</g, "<")
      .replace(/\>[\t ]+\</g, "><")
      .replace(/\>[\t ]+$/g, ">");
  }

  return { ...defaultHabit, ...overrides };
}
